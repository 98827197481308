import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import DetailsHead from "../components/detailshead"
import SingleLocation from "../components/locations/single"

const Location = ({ data, contex}) => {
	let d = data.strapiLocations;
  return(
	  <Layout>
	    <SEO title={`Carlocksmith services in ${d.name}`} />
	    <DetailsHead title="Locations in nyc"/>
	    <SingleLocation data={d}/>
	  </Layout>
  )
}

export default Location

export const query = graphql`
  query LocationTemplate($slug: String!) {
      strapiLocations(slug: { eq: $slug }) {
              name,
              slug,
              title,
              short_description,
              feature_image{
                publicURL
              }
              items{
                name,
                description
              }
        }
  }
` 
