import React, {useState} from 'react'

import styles from './single.module.css'
import Demo from '../../images/emergency/14744-5.png'
import Ellipse from '../../images/icon/Ellipse 3.svg'
import Pin from '../../images/location-pin.png'
import PinLG from '../../images/location-pin-lg.png'

import GoogleMapReact from 'google-map-react';
import Geocode from "react-geocode";
import EmailForm from './../contact/email-form'

const Single = ({data}) => {

	const [mylocation, setMyLocation] = useState('');
	const [lat, setLat] = useState(40.758556);
	const [long, setLong] = useState(-73.765434);

	let map = {};
	let maps = {};
	Geocode.setApiKey(process.env.GOOGLE_API_KEY);

	function handleApiLoaded(map, maps){
		map = map
		maps = maps
	}

	function onClickMap(location){
		setLong(location.lng)
		setLat(location.lat)
		Geocode.fromLatLng(location.lat, location.lng).then(
		  response => {
		    const address = response.results[0].formatted_address;
		    setMyLocation(address)
		  },
		  error => {
		    console.error(error);
		  }
		);
	}

	return (
		<>
		<div className={styles.mainWrapper}>
			<div className={styles.banner}>
				<div className={styles.bannerLeft}>
					<div>
						<h2>{data.title}</h2>
						<p>{data.short_description}</p>
					</div>
				</div>
				{
					data.feature_image && 
						<div className={styles.bannerRight}>
							<img src={data.feature_image.publicURL} alt={data.name}/>
						</div>
				}
			</div>

      <div className={styles.mapContactContainer}>
      	<div className={styles.mapContact}>
      		<h4>Please point your location on map and send us your help request</h4>
      		<div>Your location:</div>
      		<div className={styles.myLocation}>
      			<div className={styles.myLocationLeft}><img src={Pin} alt={`sks - Your location `}/></div>
      			<div className={styles.myLocationRight}>{mylocation || 'Select your location'}</div>
      		</div>
      		<EmailForm styles={styles} location={mylocation}/>
      	</div>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.GOOGLE_API_KEY }}
          defaultCenter={{
		      lat: 40.758556,
		      lng: -73.765434
		    }}
          defaultZoom={11}
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          onClick={onClickMap}
        >
        	<img src={PinLG} lat={lat} lng={long} className={styles.gmStyleImg} />
        </GoogleMapReact>
      </div>

			<div className={styles.container}>
				<div className={styles.wrapper}>

					{
						data.items.map((item, index)=>{
							return (
								<div className={styles.item} key={index}>
									<div className={styles.itemLeft}>
										<img src={Ellipse} alt={`sks - item number`}/>
										<span>{index + 1}</span>
									</div>
									<div className={styles.itemRight}>
										<h3>{item.name}</h3>
										<p>{item.description}</p>
									</div>
								</div>
							)
						})
					}

				</div>
			</div>
		</div>
		</>
	)
}

export default Single